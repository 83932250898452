<template>
    <v-container class="custom-form-layout" style="padding: 0!important;">
        <v-divider/>
        <div class="px-7 pt-8" style="border-bottom:solid 8px #857F7F">
            <v-text-field
                outlined
                dense
                v-model="apiData.name"
                hide-details
                maxlength="8"
            >
            </v-text-field>
            <div class="d-flex justify-space-between align-center">
                <div class="d-flex align-center">
                    <span>啟用分類</span>
                    <v-switch
                        inset
                        dense
                        color="green"
                        class="pl-2 pt-4"
                        v-model="apiData.status"
                    ></v-switch>
                </div>
                <v-btn
                    small
                    depressed
                    dark
                    @click="saveBaseData"
                    color="#12358E"
                    style="border-radius: 999px"
                >
                    {{ apiData.id !== '' ? '儲存' : '下一步'}}
                </v-btn>
            </div>
            <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
        </div>
        <div class="px-4">
            <eagle-form :form-key="formKey"></eagle-form>
        </div>
    </v-container>
</template>

<script lang="babel" type="text/babel">
import formMixin from '@/components/form/mixins/formMixin'
import formConfig from './productMenuFormConfig'

export default {
    mixins: [formMixin],
    data: () => ({
        formKey: 'product-menu-form',
        meta: {},
        activeStatus:true,
        apiData: {
            id:'',
            name:'',
            status:1
        },
        loading:false
    }),
    computed: {
        providerId() {
            return this.$store.getters['member/providerId']
        },
        productId(){
            return this.$route.params.target
        },
    },

    async created() {
        this.apiData = await this.readApi(this.productId);
    },
    methods: {
        async beforeFormInit() {
            await Promise.all([])
        },
        validateBeforeSave() {
            // TODO
        },
        async readApi(target) {
            return await this.$api.collection.productMenuApi.read(this.providerId, target)
        },
        async createApi(formData) {
            this.$root.gaLogEvent('供應商_點擊_建立商品分類')
            return await this.$api.collection.productMenuApi.create(this.providerId, formData)
        },
        async updateApi(target, formData) {
            this.$root.gaLogEvent('供應商_點擊_編輯商品分類')
            return await this.$api.collection.productMenuApi.update(this.providerId, target, formData)
        },
        async deleteApi(target) {
            this.$root.gaLogEvent('供應商_點擊_刪除商品分類')
            return await this.$api.collection.productMenuApi.delete(this.providerId, target)
        },
        getFormConfig() {
            return formConfig
        },
        async saveBaseData(){
            if(!this.apiData.id){
                let res = null
                try {
                    this.loading = true
                    res = await this.createApi(this.apiData)
                    this.$snotify.success('新增成功')
                } catch (error) {
                    console.error(error)
                    this.$snotify.error('新增失敗')
                } finally {
                    await this.$router.replace({ name: 'product-menu-create-item' ,params: { target: res.id }})
                    this.loading = false
                }
            }
            else {
                try {
                    this.loading = true
                    await this.updateApi(this.productId, this.apiData)
                    await this.readApi(this.productId);
                    this.$snotify.success('修改成功')
                } catch (error) {
                    console.error(error)
                    this.$snotify.error('修改失敗')
                } finally {
                    this.loading = false
                }
            }

        }
    },
}
</script>

<style lang="sass" type="text/sass"></style>
