import Vue from 'vue'
import { EagleFormConfigInterface } from '@/components/form/types/form'
class formConfig {
  protected vm?: Vue
  protected formKey?: string

  _defaultData() {
    return {
      name: null,
      status: 1,
    }
  }

  get(vm: Vue, formKey: string) : EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    return {
      from: () => [
        { label: 'page.product-menu', route: { name: 'product-menu' } },
      ],
      listRoute: () => ({ name: 'product-menu' }),
      afterCreateRoute: result => ({
        name: 'product-menu-update',
        params: { target: result.id },
      }),
      pageTitle: (formMeta: any, formData: AnyObject) => window.eagleLodash.get(formData, ['name']),
      pageTitleBase: () => 'module.product_menu',
      defaultData: this._defaultData,
      blocks: [
        {
          // title: 'form_block.base',
          data: {
            // name: {
            //   required: true,
            //   label: 'data.name',
            //   type: 'text',
            //   hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
            //     if(bothEmpty(originalData, data)) return false
            //     return originalData != data
            //   },
            // },
            // status: {
            //   label: 'data.status',
            //   type: 'switch',
            //   isNumberType: true,
            //   hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
            //     if(bothEmpty(originalData, data)) return false
            //     return originalData != data
            //   },
            // },
            product: {
              create: (formInfo) => {
                return formInfo.formMode == 'update'
              },
              // label: 'product-menu.data.product',
              component: () => import('@/modules/product/views/productMenuPivotList.vue')
            },
          },
        },

        // 其牠資訊
        // {
        //   title: 'form_block.others',
        //   create: ({ formMode }: FormInfoInterface) => formMode === 'update',
        //   data: {
        //     created_at: {
        //       label: 'data.created_at',
        //       type: 'time',
        //       readonly: true,
        //     },
        //     updated_at: {
        //       label: 'data.updated_at',
        //       type: 'time',
        //       readonly: true,
        //     },
        //   },
        // },
      ],
      disabledBottomNavigation:true,
      defaultGrid: () => {
        return {
          xl: 6,
          lg: 6,
          md: 6,
          sm: 12,
          xs: 12,
        }
      }
    }
  }
}

export default new formConfig()
